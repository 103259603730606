import React, { useEffect, useState } from "react";
import axios from "axios";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { connect } from "react-redux";

let playerIsLoaded = false;

const Stream = ({ user }) => {
  let height = 600;

  useEffect(() => {
    const updateHeight = () => {
      let body = document.body;
      let newHeight = 600;

      if (window.innerHeight) {
        newHeight = window.innerHeight;
      } else if (body.parentElement.clientHeight) {
        newHeight = body.parentElement.clientHeight;
      } else if (body && body.clientHeight) {
        newHeight = body.clientHeight;
      }

      height = newHeight - 150;
      if(document.getElementById("player-wrp-1").children.length > 0){
        document.getElementById("player-wrp-1").children[0].style.height = height + "px";
      }
    };

    const loadPlayerScript = async () => {
      if (!playerIsLoaded) {
        playerIsLoaded = true;

        // Get the room value from the URL query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const roomValue = urlParams.get("room");

        const urlString = user.user.streams.find((x) => x.id === roomValue).streamURL;
        const audioOnly = user.user.streams.find((x) => x.id === roomValue).audioonly;
        const token = localStorage.getItem("session");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Basic " + token,
        };

        try {
          const res = await axios.get("streams/url", { headers });
          const url = res?.data?.authsig;

          window.SLDP.init({
            reconnects: 150,
            container: "player-wrp-1",
            audio_only: audioOnly,
            stream_url: urlString + url,
            buffering: 800,
            autoplay: true,
            muted: true,
            height: height,
            sync_buffer: 2000,
          });

          const playerWrapper = document.getElementById("player-wrp-1").parentElement;
          playerWrapper.style.alignItems = "center";
          playerWrapper.style.justifyContent = "center";
        } catch (error) {
          console.log("Error loading stream");
        }
      }
    };

    const scriptLoaded = () => {
      updateHeight();
      loadPlayerScript();
    };

    // Attach resize event listener
    window.addEventListener("resize", updateHeight);

    if (!playerIsLoaded) {
      const script = document.createElement("script");
      script.src = "https://softvelum.com/player/releases/sldp-v2.21.2.min.js";
      script.async = true;
      script.onload = scriptLoaded;

      document.body.appendChild(script);
    }

    return () => {
      // Remove resize event listener on component unmount
      window.removeEventListener("resize", updateHeight);
      console.log("Component unmounted");
    };
  }, [height]);

  return (
    <EuiFlexGroup wrap>
      <EuiFlexItem style={{ minWidth: 300, alignItems: "center", justifyContent: "center" }}>
        <div id="player-wrp-1" className="player-wrp"></div>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default connect((state) => ({ user: state.auth.user }))(Stream);