import React, { useEffect } from "react";
import axios from "axios";
import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import { connect } from "react-redux";

let playerIsLoaded = false;
const sldpPlayers = [];

const StreamMultiroom = ({ user }) => {
  useEffect(() => {
    const loadPlayerScript = async () => {
      if (!playerIsLoaded) {
        const script = document.createElement("script");
        script.src = "https://softvelum.com/player/releases/sldp-v2.25.0.min.js";
        script.async = true;
        script.onload = scriptLoaded;

        document.body.appendChild(script);
      }
    };

    const scriptLoaded = async () => {
      if (!playerIsLoaded) {
        
        playerIsLoaded = true;
        const body = document.body;
        let height = 600;
        if (window.innerHeight) {
          height = window.innerHeight;
        } else if (body.parentElement.clientHeight) {
          height = body.parentElement.clientHeight;
        } else if (body && body.clientHeight) {
          height = body.clientHeight;
        }

        const token = localStorage.getItem("session");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Basic " + token,
        };
        for (let i = 0; i < user.user.streams.length; i++) {
          debugger;
          try {
            if(user.user.streams[i].type = "single"){
              const res = await axios.get("streams/url", { headers });
              const url = res?.data?.authsig;
              const player = window.SLDP.init({
                container: `player-wrp-${i + 1}`,
                stream_url: user.user.streams[i].streamURL + url,
                buffering: 800,
                autoplay: true,
                muted: true,
                height: (height - 150) / 2,
              });


              sldpPlayers[i] = player;
              const playerWrapper = document.getElementById(`player-wrp-${i + 1}`).parentElement;
              playerWrapper.style.alignItems = "center";
              playerWrapper.style.justifyContent = "center";
            }
          } catch (error) {
            console.log("Error loading stream");
          }
        }

      }
    };

    loadPlayerScript();

    return () => {
      console.log("Component unmounted");
    };
  }, [user.streams]);


  return (
    <div>
      <EuiFlexGroup alignItems="center" wrap>
        {user.user.streams?.map((stream, index) => (
          <EuiFlexItem key={index} style={{ minWidth: 100, alignItems: "center", justifyContent: "center" }}>
            <div id={`player-wrp-${index + 1}`} className="player-wrp"></div>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </div>
  );
  };

export default connect((state) => ({ user: state.auth.user }))(StreamMultiroom);